import { JsonUtils } from '../../../utils/json.util'
import SleepSummaryData from './summary.data'

export default class SleepAppleSummary {
    private _in_bed: SleepSummaryData | undefined
    private _awake: SleepSummaryData | undefined
    private _asleep: SleepSummaryData | undefined
    private _rem: SleepSummaryData | undefined
    private _core: SleepSummaryData | undefined
    private _essential: SleepSummaryData | undefined
    private _deep: SleepSummaryData | undefined

    get in_bed(): SleepSummaryData | undefined {
        return this._in_bed
    }

    set in_bed(value: SleepSummaryData | undefined) {
        this._in_bed = value
    }

    get awake(): SleepSummaryData | undefined {
        return this._awake
    }

    set awake(value: SleepSummaryData | undefined) {
        this._awake = value
    }

    get asleep(): SleepSummaryData | undefined {
        return this._asleep
    }

    set asleep(value: SleepSummaryData | undefined) {
        this._asleep = value
    }

    get rem(): SleepSummaryData | undefined {
        return this._rem
    }

    set rem(value: SleepSummaryData | undefined) {
        this._rem = value
    }

    get core(): SleepSummaryData | undefined {
        return this._core
    }

    set core(value: SleepSummaryData | undefined) {
        this._core = value
    }

    get essential(): SleepSummaryData | undefined {
        return this._essential
    }

    set essential(value: SleepSummaryData | undefined) {
        this._essential = value
    }

    get deep(): SleepSummaryData | undefined {
        return this._deep
    }

    set deep(value: SleepSummaryData | undefined) {
        this._deep = value
    }

    public fromJSON(json: any): SleepAppleSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.in_bed !== undefined) {
            this.in_bed = new SleepSummaryData().fromJSON(json.in_bed)
        }

        if (json.awake !== undefined) {
            this.awake = new SleepSummaryData().fromJSON(json.awake)
        }

        if (json.asleep !== undefined) {
            this.asleep = new SleepSummaryData().fromJSON(json.asleep)
        }

        if (json.rem !== undefined) {
            this.rem = new SleepSummaryData().fromJSON(json.rem)
        }

        if (json.core !== undefined) {
            this.core = new SleepSummaryData().fromJSON(json.core)
        }

        if (json.essential !== undefined) {
            this.essential = new SleepSummaryData().fromJSON(json.essential)
        }

        if (json.deep !== undefined) {
            this.deep = new SleepSummaryData().fromJSON(json.deep)
        }

        return this
    }

    public toJSON(): any {
        return {
            in_bed: this.in_bed ? this.in_bed.toJSON() : undefined,
            awake: this.awake ? this.awake.toJSON() : undefined,
            asleep: this.asleep ? this.asleep.toJSON() : undefined,
            rem: this.rem ? this.rem.toJSON() : undefined,
            core: this.core ? this.core.toJSON() : undefined,
            essential: this.essential ? this.essential.toJSON() : undefined,
            deep: this.deep ? this.deep.toJSON() : undefined
        }
    }
}
