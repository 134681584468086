import { Reducer } from 'redux'
import { IAvatarList, IUserState, UserTypes } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../root.types'
import User, { TypesUser } from '../../application/models/users/users'
import { AssociatesTypes } from './associates/types'
import { PatientTypes } from './patient/types'

export const INITIAL_STATE: IUserState = {
    create: {
        avatar: {},
        user: undefined,
        dialog: false,
        loading: false,
        success: false,
        error: false,
        loadingAvatar: false,
        dataError: {}
    },
    list: {
        users: [],
        avatars: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        dialog: false,
        id: '',
        loading: false,
        success: false,
        error: false
    },
    userLogged: {
        error: false,
        loading: false,
        success: false,
        userLogged: new User()
    }
}

export const resetCreate = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: INITIAL_STATE.create }
}

export const resetList = (state: IUserState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const changeUser = (state: IUserState = INITIAL_STATE, action: IActionType<{ user: any }>) => {
    const { user } = action.payload
    return { ...state, create: { ...state.create, user } }
}

export const changePaginator = (state: IUserState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    paginator?: IPaginator
}>) => {
    const { userType, paginator } = action.payload
    return { ...state, list: { ...state.list, paginator, userType } }
}

export const changeSearchPaginator = (state: IUserState = INITIAL_STATE, action: IActionType<{ search: ISearch }>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeRemoveDialog = (state: IUserState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    id: string,
    userType: TypesUser
}>) => {
    const { dialog, id, userType } = action.payload
    return { ...state, remove: { ...state.remove, dialog, id, userType } }
}

export const changeDialog = (state: IUserState = INITIAL_STATE, action: IActionType<{ dialog: boolean, user }>) => {
    const { dialog, user } = action.payload
    return { ...state, create: { ...state.create, dialog, user: user || state.create.user } }
}

export const changeAvatarFile = (state: IUserState = INITIAL_STATE, action: IActionType<{ avatar: File }>) => {
    const { avatar } = action.payload
    return { ...state, create: { ...state.create, avatar: { ...state.create.avatar, file: avatar } } }
}

export const changeAvatarImg = (state: IUserState = INITIAL_STATE, action: IActionType<{ avatar: any }>) => {
    const { avatar } = action.payload
    return { ...state, create: { ...state.create, avatar: { ...state.create.avatar, img: avatar } } }
}

export const getAddressRequest = (state: IUserState = INITIAL_STATE) => {
    const user = state.create.user
    if (user?.address) {
        user.address = undefined
    }
    return { ...state, create: request(state.create) }
}

export const changeAddress = (state: IUserState = INITIAL_STATE, action) => {
    const { address } = action.payload
    const user = state.create.user
    user.address = address
    return {
        ...state, create: success({ ...state.create, user })
    }
}

export const createRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ user }>) => {
    const { user } = action.payload
    return { ...state, create: success({ ...state.create, user, dialog: true }) }
}

export const createFailure = (state: IUserState = INITIAL_STATE, action: IActionType<{ error }>) => {
    const dataError = action?.payload?.error || state.create.dataError
    return { ...state, create: failure({ ...state.create, dataError }) }
}

export const cleanCreateError = (state: IUserState = INITIAL_STATE) => {
    return {
        ...state, create: {
            ...state.create,
            error: false,
            dataError: {}
        }
    }
}


export const loadRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ users: any[], headers }>) => {
    const { users, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, list: success({ ...state.list, users, paginator }) }
}

export const loadFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadAvatarRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: { ...state.create, loadingAvatar: true } }
}

export const loadAvatarSuccess = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: { ...state.create, loadingAvatar: false } }
}

export const loadAvatarFailure = (state: IUserState = INITIAL_STATE) => {
    return {
        ...state,
        create: {
            ...state.create,
            loadingAvatar: false,
            avatar: {
                file: '',
                img: ''
            }
        }
    }
}

export const loadMoreRequest = (state: IUserState = INITIAL_STATE, action: IActionType<{ userType: TypesUser, paginator: IPaginator }>) => {
    const { userType, paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator, userType }) }
}

export const loadMoreSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ users: any[], headers }>) => {
    const { users, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    const stateUsers = state.list.users.concat(users)
    const avatars: IAvatarList[] = users.map(user => {
        return { userId: user.id, status: 'loading', img: '' }
    })
    const resultAvatars = state.list.avatars.concat(avatars)
    return { ...state, list: success({ ...state.list, users: stateUsers, paginator, avatars: resultAvatars }) }
}

export const loadMultipleAvatarSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ avatar: any, userId }>) => {
    const { userId, avatar } = action.payload
    const filter = state.list.avatars.filter(element => element.userId !== userId)
    return {
        ...state,
        list: { ...state.list, avatars: filter.concat({ status: 'available', userId, img: avatar }) }
    }
}

export const loadMultipleAvatarFailure = (state: IUserState = INITIAL_STATE, action: IActionType<{ avatar: any, userId }>) => {
    const { userId } = action.payload
    const filter = state.list.avatars.filter(element => element.userId !== userId)
    return {
        ...state,
        list: { ...state.list, avatars: filter.concat({ status: '404', userId, img: '' }) }
    }
}

export const updateSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ user }>) => {
    const { user } = action.payload
    return { ...state, create: success({ ...state.create, user }) }
}

export const removeRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, remove: request({ ...state.remove, dialog: true }) }
}

export const removeSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ userId: string }>) => {
    const { userId } = action.payload
    const users = state.list.users?.filter(user => user?.id !== userId)
    return {
        ...state,
        list: {
            ...state.list,
            users
        },
        remove: success({ ...INITIAL_STATE.remove })
    }
}

export const removeFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, remove: failure(state.remove) }
}

export const removeAvatarRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: { ...state.create, loadingAvatar: true } }
}

export const removeAvatarSuccess = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: { ...state.create, loadingAvatar: false, avatar: '' } }
}

export const removeAvatarFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: { ...state.create, loadingAvatar: false } }
}

export const saveGoalsSuccess = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: success({ ...state.create }) }
}

export const getUserLoggedRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, userLogged: request(state.userLogged) }
}

export const getUserLoggedSuccess = (
    state: IUserState = INITIAL_STATE,
    action: IActionType<{ userLogged: any }>) => {
    const { userLogged } = action.payload
    return { ...state, userLogged: success({ ...state.userLogged, userLogged }) }
}

export const getUserLoggedFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, userLogged: failure(state.userLogged) }
}

const reducer: Reducer<IUserState> = createReducer<IUserState>(INITIAL_STATE, {
    [UserTypes.RESET_CREATE]: resetCreate,
    [UserTypes.RESET_LIST]: resetList,

    [UserTypes.CHANGE_USER]: changeUser,
    [UserTypes.CHANGE_PAGINATOR]: changePaginator,
    [UserTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [UserTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [UserTypes.CHANGE_DIALOG]: changeDialog,
    [UserTypes.CHANGE_AVATAR_FILE]: changeAvatarFile,
    [UserTypes.CHANGE_AVATAR_IMG]: changeAvatarImg,

    [UserTypes.CREATE_REQUEST]: createRequest,
    [UserTypes.CREATE_SUCCESS]: createSuccess,
    [UserTypes.CREATE_FAILURE]: createFailure,
    [UserTypes.CLEAN_CREATE_ERROR]: cleanCreateError,

    [UserTypes.LOAD_REQUEST]: loadRequest,
    [UserTypes.LOAD_SUCCESS]: loadSuccess,
    [UserTypes.LOAD_FAILURE]: loadFailure,

    [UserTypes.LOAD_AVATAR_REQUEST]: loadAvatarRequest,
    [UserTypes.LOAD_AVATAR_SUCCESS]: loadAvatarSuccess,
    [UserTypes.LOAD_AVATAR_FAILURE]: loadAvatarFailure,

    [UserTypes.LOAD_MORE_REQUEST]: loadMoreRequest,
    [UserTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [UserTypes.LOAD_MORE_FAILURE]: loadFailure,

    [UserTypes.LOAD_MULTIPLE_AVATAR_SUCCESS]: loadMultipleAvatarSuccess,
    [UserTypes.LOAD_MULTIPLE_AVATAR_FAILURE]: loadMultipleAvatarFailure,

    [UserTypes.FIND_REQUEST]: createRequest,
    [UserTypes.FIND_SUCCESS]: updateSuccess,
    [UserTypes.FIND_FAILURE]: createFailure,

    [UserTypes.UPDATE_REQUEST]: createRequest,
    [UserTypes.UPDATE_SUCCESS]: updateSuccess,
    [UserTypes.UPDATE_FAILURE]: createFailure,

    [UserTypes.REMOVE_REQUEST]: removeRequest,
    [UserTypes.REMOVE_SUCCESS]: removeSuccess,
    [UserTypes.REMOVE_FAILURE]: removeFailure,

    [UserTypes.GET_ADDRESS_REQUEST]: getAddressRequest,
    [UserTypes.GET_ADDRESS_SUCCESS]: changeAddress,
    [UserTypes.GET_ADDRESS_FAILURE]: createFailure,

    [UserTypes.REMOVE_AVATAR_REQUEST]: removeAvatarRequest,
    [UserTypes.REMOVE_AVATAR_SUCCESS]: removeAvatarSuccess,
    [UserTypes.REMOVE_AVATAR_FAILURE]: removeAvatarFailure,

    [AssociatesTypes.LOAD_BY_HEALTH_PROFESSIONAL_ID]: loadRequest,
    [AssociatesTypes.LOAD_MORE_BY_HEALTH_PROFESSIONAL_ID]: loadMoreRequest,

    [AssociatesTypes.REMOVE_REQUEST]: removeRequest,
    [AssociatesTypes.REMOVE_SUCCESS]: removeSuccess,

    [AssociatesTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [AssociatesTypes.REMOVE_ASSOCIATED_REQUEST]: removeRequest,
    [AssociatesTypes.REMOVE_ASSOCIATED_SUCCESS]: removeSuccess,

    [PatientTypes.SAVE_GOALS_REQUEST]: createRequest,
    [PatientTypes.SAVE_GOALS_SUCCESS]: saveGoalsSuccess,
    [PatientTypes.SAVE_GOALS_FAILURE]: createFailure,

    [UserTypes.GET_USER_LOGGED_REQUEST]: getUserLoggedRequest,
    [UserTypes.GET_USER_LOGGED_SUCCESS]: getUserLoggedSuccess,
    [UserTypes.GET_USER_LOGGED_FAILURE]: getUserLoggedFailure,

    [UserTypes.REMOVE_USER_LOGGED_REQUEST]: removeRequest,
    [UserTypes.REMOVE_USER_LOGGED_SUCCESS]: removeSuccess,
    [UserTypes.REMOVE_USER_LOGGED_FAILURE]: removeFailure
})

export default reducer
