import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { UserLocation, UserTypes } from './types'
import { LanguageOptions } from '../../../i18n'
import { TypesUser } from '../../application/models/users/users'
import Address from '../../application/models/users/address'

/* Action for reset state */
export const resetCreate = () => action(UserTypes.RESET_CREATE)

export const resetList = () => action(UserTypes.RESET_LIST)

/* Actions for change user */
export const changeUser = (user: any) => action(UserTypes.CHANGE_USER, { user })

export const changePaginator = (userType: TypesUser, paginator?: IPaginator) => [
    action(UserTypes.CHANGE_PAGINATOR, { userType, paginator }),
    loadRequest(userType, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(UserTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveDialog = (dialog: boolean, id: string) => action(UserTypes.CHANGE_REMOVE_DIALOG, {
    dialog,
    id
})

export const changeDialog = (dialog: boolean, user?: any) => {
    const pipe: any = []
    if (dialog && user?.id) {
        pipe.push(loadAvatarRequest(user.id))
    }
    if (!dialog) {
        pipe.push(resetCreate())
    }
    return pipe.concat(action(UserTypes.CHANGE_DIALOG, { dialog, user }))

}

export const changeAvatarFile = (avatar?: File) => action(UserTypes.CHANGE_AVATAR_FILE, { avatar })

export const changeAvatarImg = (avatar?: any) => action(UserTypes.CHANGE_AVATAR_IMG, { avatar })

export const changeZipCode = (zip_code: string) => action(UserTypes.CHANGE_ZIP_CODE, { zip_code })

/* Actions for create user */
export const createRequest = (user: any, avatar: any, userLocation: UserLocation) => action(
    UserTypes.CREATE_REQUEST,
    { user, avatar, userLocation }
)

export const createSuccess = (user: any) => [
    action(UserTypes.CREATE_SUCCESS, { user }),
    user.type !== TypesUser.PATIENT && resetCreate()
]

export const createFailure = (error: any) => action(UserTypes.CREATE_FAILURE, { error })

export const cleanCreateError = () => action(UserTypes.CLEAN_CREATE_ERROR)

/* Actions for load users */
export const loadRequest = (userType: TypesUser, paginator?: IPaginator) => action(UserTypes.LOAD_REQUEST, {
    userType,
    paginator
})

export const loadSuccess = (response: IAxiosResponse<any[]>) => action(UserTypes.LOAD_SUCCESS, {
    users: response.data,
    headers: response.headers
})

export const loadFailure = () => action(UserTypes.LOAD_FAILURE)

export const loadMoreRequest = (userType: TypesUser, paginator: IPaginator) => action(UserTypes.LOAD_MORE_REQUEST, {
    userType,
    paginator
})

export const loadMoreSuccess = (response: IAxiosResponse<any[]>) => action(UserTypes.LOAD_MORE_SUCCESS, {
    users: response.data,
    headers: response.headers
})

export const loadMoreFailure = () => action(UserTypes.LOAD_MORE_FAILURE)

/* Actions for load avatar */
export const loadAvatarRequest = (id: string) => action(UserTypes.LOAD_AVATAR_REQUEST, { id })

export const loadAvatarSuccess = (avatar: any) => [
    changeAvatarImg(avatar),
    action(UserTypes.LOAD_AVATAR_SUCCESS)
]

export const loadAvatarFailure = () => action(UserTypes.LOAD_AVATAR_FAILURE)

export const loadMultipleAvatarSuccess = (userId: string, avatar: any) => action(
    UserTypes.LOAD_MULTIPLE_AVATAR_SUCCESS, { userId, avatar })

export const loadMultipleAvatarFailure = (userId: any) => action(UserTypes.LOAD_MULTIPLE_AVATAR_FAILURE, { userId })

/* Actions for find user */
export const findRequest = (id: string, userType: TypesUser) => [
    action(UserTypes.FIND_REQUEST, { id, userType }),
    loadAvatarRequest(id)
]

export const findSuccess = (user: any) => action(UserTypes.FIND_SUCCESS, { user })

export const findFailure = () => action(UserTypes.FIND_FAILURE)

/* Actions for update user */
export const updateRequest = (user: any, avatar: any, userLocation: UserLocation) => action(
    UserTypes.UPDATE_REQUEST,
    { user, avatar, userLocation }
)

export const updateSuccess = (user: any) => action(UserTypes.UPDATE_SUCCESS, { user })

export const updateLanguageSuccess = (user: any) => action(UserTypes.UPDATE_SUCCESS, { user })

export const updateFailure = (error) => action(UserTypes.UPDATE_FAILURE, { error })

/* Actions for remove user */
export const removeRequest = (id: string) => action(UserTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (userId: string) => action(UserTypes.REMOVE_SUCCESS, { userId })

export const removeFailure = () => action(UserTypes.REMOVE_FAILURE)

export const removeUserLoggedRequest = (id?: string) => action(UserTypes.REMOVE_USER_LOGGED_REQUEST, { id })

export const removeUserLoggedSuccess = (id?: string) => action(UserTypes.REMOVE_USER_LOGGED_SUCCESS, { id })

export const removeUserLoggedFailure = () => action(UserTypes.REMOVE_USER_LOGGED_FAILURE)

/* Actions for update user */
export const updateLanguageRequest = (userId: string, language: LanguageOptions, userType: TypesUser) => action(
    UserTypes.UPDATE_LANGUAGE_REQUEST, {
        userId,
        language,
        userType
    })

/* Actions for update address */
export const getAddressRequest = (zip_code: string, oldAddress?: Address) => action(
    UserTypes.GET_ADDRESS_REQUEST,
    { zip_code, oldAddress }
)

export const getAddressSuccess = (address: any) => action(UserTypes.GET_ADDRESS_SUCCESS, { address })

export const getAddressFailure = () => action(UserTypes.GET_ADDRESS_FAILURE)

/* Actions for remove user avatar */
export const removeAvatarRequest = (id: string) => action(UserTypes.REMOVE_AVATAR_REQUEST, { id })

export const removeAvatarSuccess = () => action(UserTypes.REMOVE_AVATAR_SUCCESS)

export const removeAvatarFailure = () => action(UserTypes.REMOVE_AVATAR_FAILURE)


/* Actions for Health professional's patients and caregivers */
export const getAssociatedsRequest = (hpId: string, userType: TypesUser, paginator?: IPaginator) => action(
    UserTypes.GET_ASSOCIATEDS_REQUEST, {
        hpId,
        userType,
        paginator
    })

export const getAssociatedsSuccess = (userType: TypesUser, response: IAxiosResponse<any[]>) => action(UserTypes.GET_ASSOCIATEDS_SUCCESS, {
    userType,
    users: response.data,
    headers: response.headers
})

export const getAssociatedsFailure = (error: ErrorEvent) => action(UserTypes.GET_ASSOCIATEDS_FAILURE)

export const getMoreAssociatedsRequest = (userId: string, userType: TypesUser, paginator: IPaginator) => action(
    UserTypes.GET_MORE_ASSOCIATEDS_REQUEST, { userId, userType, paginator }
)

export const getMoreAssociatedsSuccess = (userType: TypesUser, response: IAxiosResponse<any[]>) => action(
    UserTypes.GET_MORE_ASSOCIATEDS_SUCCESS, {
        userType,
        users: response.data,
        headers: response.headers
    })

export const getMoreAssociatedsFailure = () => action(UserTypes.GET_MORE_ASSOCIATEDS_FAILURE)

/* Actions for Load User Logged */
export const getUserLoggedRequest = () => action(UserTypes.GET_USER_LOGGED_REQUEST)

export const getUserLoggedSuccess = (userLogged: any) => action(
    UserTypes.GET_USER_LOGGED_SUCCESS,
    { userLogged }
)

export const getUserLoggedFailure = () => action(UserTypes.GET_USER_LOGGED_FAILURE)
