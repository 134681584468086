import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import { LanguageOptions } from '../../../i18n'
import { TypesUser } from '../../application/models/users/users'

export const UserTypes = createTypes(`
    RESET_CREATE 
    RESET_LIST

    CHANGE_USER 
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    CHANGE_DIALOG 
    CHANGE_AVATAR_FILE
    CHANGE_AVATAR_IMG

    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE
    CLEAN_CREATE_ERROR

    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE

    LOAD_AVATAR_REQUEST
    LOAD_AVATAR_SUCCESS
    LOAD_AVATAR_FAILURE
    LOAD_MULTIPLE_AVATAR_SUCCESS
    LOAD_MULTIPLE_AVATAR_FAILURE

    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE

    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE

    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
    
    UPDATE_LANGUAGE_REQUEST

    GET_ADDRESS_REQUEST
    GET_ADDRESS_SUCCESS
    GET_ADDRESS_FAILURE
    
    REMOVE_AVATAR_REQUEST
    REMOVE_AVATAR_SUCCESS
    REMOVE_AVATAR_FAILURE
    
    GET_USER_LOGGED_REQUEST
    GET_USER_LOGGED_SUCCESS
    GET_USER_LOGGED_FAILURE
    
    REMOVE_USER_LOGGED_REQUEST
    REMOVE_USER_LOGGED_SUCCESS
    REMOVE_USER_LOGGED_FAILURE 
`,
    {
        prefix: '@user/'
    }
)

/**
 * Action types
 */
export interface IActionCreate {
    readonly user: any
    readonly avatar?: any
    readonly userLocation: UserLocation
}

export enum UserLocation {
    LIST = 'list',
    PROFILE = 'profile',
    ASSOCIATES = 'associates'
}

export interface IActionFind {
    readonly id: string
    readonly userType: TypesUser
}

export interface IActionLoad {
    readonly userType: TypesUser
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}

export interface IActionUpdateLanguage {
    readonly userId: string
    readonly language: LanguageOptions
    readonly userType: TypesUser
}


/**
 * State types
 */
export interface IAvatar {
    readonly file?: File
    readonly img?: string
}

interface ICreateState extends IComponentState {
    readonly avatar: IAvatar
    readonly user: any
    readonly dialog: boolean
    readonly loadingAvatar: boolean
    readonly dataError: any
}

export interface IAvatarList {
    readonly userId: string
    readonly status: 'available' | 'loading' | '404'
    readonly img: any
}

export interface IListState extends IComponentState {
    readonly users: any
    readonly avatars: IAvatarList[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
    readonly userType?: TypesUser
}

export interface IUserLogged extends IComponentState{
    readonly userLogged: any
}

export interface IUserState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly userLogged: IUserLogged
}
