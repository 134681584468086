import axios from 'axios'
import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import UtilDatePicker from '../components/date.and.hour/date'
import { LanguageOptions } from '../i18n'
import { MAP_TYPE_USER_TO_ENDPOINT, TypesUser } from '../store/application/models/users/users'
import Admin from '../store/application/models/users/admin'
import HealthProfessional from '../store/application/models/users/health.professional'
import Caregiver from '../store/application/models/users/caregiver'
import Address from '../store/application/models/users/address'
import Patient from '../store/application/models/users/patient'
import { FactoryUser } from '../store/application/utils/factory.user'

class UserService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(user: any): Promise<any> {
        let url: string = ''
        switch (user.type) {
            case TypesUser.ADMIN:
                url = `${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[user.type]}`
                break
            case TypesUser.HEALTH_PROFESSIONAL:
                url = `${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[user.type]}`
                break
            case TypesUser.CAREGIVER:
                url = `${this.apiVersion}/healthprofessionals/${user.health_professional_id}/caregivers`
                break
            case TypesUser.PATIENT:
                url = `${this.apiVersion}/healthprofessionals/${user.health_professional_id}/patients`
                break
            default:
                url = ''
        }
        return axiosInstance
            .post(url, {
                ...user.toJSON(),
                birth_date: user?.birth_date ? UtilDatePicker.usaFormatDate(user?.birth_date) : undefined
            })
            .then(response => user.fromJSON(response.data))
    }

    public getAll(userType: TypesUser, paginator?: IPaginator): Promise<IAxiosResponse<any[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}`, { params })
            .then((response) => {
                switch (userType) {
                    case TypesUser.ADMIN:
                        return {
                            data: response.data.map(adm => new Admin().fromJSON(adm)),
                            headers: response.headers
                        }
                    case TypesUser.HEALTH_PROFESSIONAL:
                        return {
                            data: response.data.map(hp => new HealthProfessional().fromJSON(hp)),
                            headers: response.headers
                        }
                    case TypesUser.CAREGIVER:
                        return {
                            data: response.data.map(hp => new Caregiver().fromJSON(hp)),
                            headers: response.headers
                        }
                    case TypesUser.PATIENT:
                        return {
                            data: response.data.map(hp => new Patient().fromJSON(hp)),
                            headers: response.headers
                        }
                    default:
                        return response.data
                }
            })
    }

    public getById(userId: string, userType: TypesUser): Promise<any> {
        return axiosInstance.get(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}`)
            .then(response => FactoryUser.build(response?.data, userType))
    }

    public update(user: any): Promise<any> {
        user.password = undefined
        return axiosInstance
            .patch(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[user.type]}/${user.id}`, {
                ...user.toJSON(),
                birth_date: user?.birth_date ? UtilDatePicker.usaFormatDate(user?.birth_date) : undefined
            })
            .then(response => user.fromJSON(response.data))
    }

    public updateLanguage(userId: string, language: LanguageOptions, userType: TypesUser): Promise<any> {
        return axiosInstance.patch(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}`, { language })
            .then(response => FactoryUser.build(response?.data, userType))
    }

    public remove(userId: string) {
        return axiosInstance.delete(`${this.apiVersion}/users/${userId}`)
    }

    public getZipCode(zip_code: string): Promise<Address | undefined> {
        return axios.get(`https://viacep.com.br/ws/${zip_code}/json/`)
            .then(response => {
                if (response.data.erro) {
                    return undefined
                }
                return new Address().fromJSON({
                    id: undefined,
                    street: response.data.logradouro,
                    district: response.data.bairro,
                    city: response.data.localidade,
                    state: response.data.uf,
                    zip_code: response.data.cep?.replace(/[^0-9]/g, ''),
                    number: undefined
                })
            })
    }

    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        return params
    }
}

export default new UserService()
