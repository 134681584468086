import { Reducer } from 'redux'
import { IDashboardData, ILastMeasurementsData, IPatientState, PatientTypes } from './types'
import { createReducer } from 'reduxsauce'
import TimeSeries from '../../application/models/time.series/time.series'
import HeartRate from '../../application/models/time.series/heart.rate/heart.rate'
import { failure, IActionType, IPaginator, request, success } from '../root.types'
import Sleep from '../../application/models/activity/sleep/sleep'
import BloodGlucose from '../../application/models/measurements/blood.glucose'
import BloodPressure from '../../application/models/measurements/blood.pressure'
import BodyTemperature from '../../application/models/measurements/body.temperature'
import Weight from '../../application/models/measurements/weight'
import BodyFat from '../../application/models/measurements/body.fat'
import WaistCircumference from '../../application/models/measurements/waist.circumference'
import HandGrip from '../../application/models/measurements/hand.grip'
import CalfCircumference from '../../application/models/measurements/calf.circumference'
import Height from '../../application/models/measurements/height'
import IntradayHeartRate from '../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import Measurement from '../../application/models/measurements/measurement'
import { TypeMeasurement } from '../../application/models/measurements/measurements.last'
import HeartRateVariability from '../../application/models/measurements/heart.rate.variability'
import moment from 'moment'
import OxygenSaturation from '../../application/models/measurements/oxygen.saturation'
import GaitSpeed from '../../application/models/measurements/gait.speed'
import TSGaitSpeed from '../../application/models/measurements/ts.gait.speed'
import ManualGaitSpeed from '../../application/models/measurements/manual.gait.speed'

export const INITIAL_STATE: IPatientState = {
    dashboard: {
        loading: false,
        error: false,
        success: false,
        steps: new TimeSeries(),
        calories: new TimeSeries(),
        active_minutes: new TimeSeries(),
        distance: new TimeSeries(),
        heart_rate: new HeartRate(),
        sleep: {
            loading: false,
            error: false,
            success: false,
            data: []
        },
        heartRateVariability: {
            loading: false,
            error: false,
            success: false,
            heartRateVariability: new HeartRateVariability()
        },
        gaitSpeed: {
            data: [],
            loading: false,
            error: false,
            success: false
        }
    },
    lastMeasurement: {
        blood_glucose: new BloodGlucose(),
        blood_pressure: new BloodPressure(),
        body_temperature: new BodyTemperature(),
        weight: new Weight(),
        body_fat: new BodyFat(),
        height: new Height(),
        waist_circumference: new WaistCircumference(),
        hand_grip: new HandGrip(),
        calf_circumference: new CalfCircumference(),
        oxygen_saturation: new OxygenSaturation(),
        gait_speed: new GaitSpeed(),
        ts_gait_speed: new TSGaitSpeed(),
        manual_gait_speed: new ManualGaitSpeed(),
        loading: false,
        success: false,
        error: false
    },
    addMeasurement: {
        measure: new Weight(),
        loading: false,
        success: false,
        error: false,
        dialog: false
    },
    heartRate: {
        heart_rate: new IntradayHeartRate(),
        loading: false,
        success: false,
        error: false
    },
    measurements: {
        chart: {
            measurements: [],
            loading: false,
            success: false,
            error: false
        },
        list: {
            measurements: [],
            loading: false,
            success: false,
            error: false,
            paginator: {
                first: 0,
                rows: 10,
                page: 0,
                pageCount: 0,
                totalRecords: 0,
                search: {
                    key: '',
                    value: ''
                }
            }
        },
        remove: {
            ids: [],
            dialog: false,
            loading: false,
            success: false,
            error: false
        }
    }
}

export const loadDashboardRequest = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        dashboard: request({
            ...state.dashboard,
            sleep: request(state.dashboard.sleep),
            heartRateVariability: request(state.dashboard.heartRateVariability),
            gaitSpeed: request(state.dashboard.gaitSpeed)
        })
    }
}

export const loadDashboardSuccess = (state: IPatientState = INITIAL_STATE, action: IActionType<{ data: IDashboardData }>) => {
    const { data } = action.payload
    return { ...state, dashboard: success({ ...state.dashboard, ...data }) }
}

export const loadDashboardFailure = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, dashboard: failure(state.dashboard) }
}

export const loadDashboardSleepSuccess = (state: IPatientState = INITIAL_STATE, action: IActionType<{ data: Sleep[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        dashboard: success({ ...state.dashboard, sleep: success({ ...state.dashboard.sleep, data }) })
    }
}

export const loadDashboardSleepFailure = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, dashboard: { ...state.dashboard, sleep: failure(state.dashboard.sleep) } }
}

export const loadDashboardHRVSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ heartRateVariability: HeartRateVariability }>) => {
    const { heartRateVariability } = action.payload
    return {
        ...state,
        dashboard: success({
            ...state.dashboard,
            heartRateVariability: success({
                ...state.dashboard.heartRateVariability,
                heartRateVariability
            })
        })
    }
}

export const loadDashboardHRVFailure = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            heartRateVariability: failure({ ...state.dashboard.heartRateVariability })
        }
    }
}

export const loadDashboardGaitSpeedSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ data: GaitSpeed }>) => {
    const { data } = action.payload
    return {
        ...state,
        dashboard: success({
            ...state.dashboard,
            gaitSpeed: success({
                ...state.dashboard.gaitSpeed,
                data
            })
        })
    }
}

export const loadDashboardGaitSpeedFailure = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            gaitSpeed: failure({ ...state.dashboard.gaitSpeed })
        }
    }
}

export const resetLastMeasurement = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        lastMeasurement: {
            ...INITIAL_STATE.lastMeasurement
        }
    }
}

export const loadLastMeasurementRequest = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, lastMeasurement: request({ ...state.lastMeasurement }) }
}

export const loadLastMeasurementSuccess = (state: IPatientState = INITIAL_STATE, action: IActionType<{ data: ILastMeasurementsData }>) => {
    const { data } = action.payload
    return {
        ...state,
        lastMeasurement: success({
            ...state.lastMeasurement,
            blood_glucose: data.blood_glucose,
            blood_pressure: data.blood_pressure,
            body_temperature: data.body_temperature,
            weight: data.weight,
            body_fat: data.body_fat,
            height: data.height,
            waist_circumference: data.waist_circumference,
            hand_grip: data.hand_grip,
            calf_circumference: data.calf_circumference,
            oxygen_saturation: data.oxygen_saturation,
            gait_speed: data.gait_speed,
            ts_gait_speed: data.ts_gait_speed,
            manual_gait_speed: data.manual_gait_speed
        })
    }
}

export const loadLastMeasurementFailure = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, lastMeasurement: failure(state.lastMeasurement) }
}

export const loadLastHeartRateRequest = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, heartRate: request({ ...state.heartRate }) }
}

export const loadLastHeartRateSuccess = (state: IPatientState = INITIAL_STATE, action: IActionType<{ data: ILastMeasurementsData }>) => {
    const { data } = action.payload
    return { ...state, heartRate: success({ ...state.heartRate, heart_rate: data }) }
}

export const loadLastHeartRateFailure = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, heartRate: failure(state.heartRate) }
}

export const resetDashboard = () => {
    return INITIAL_STATE
}

export const resetMeasurements = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state, addMeasurement: {
            ...INITIAL_STATE.addMeasurement, dialog: state.addMeasurement.dialog
        }
    }
}

export const changeDialogAddMeasurement = (state: IPatientState = INITIAL_STATE, action: IActionType<{ dialog: boolean }>) => {
    const { dialog } = action.payload
    return { ...state, addMeasurement: { ...state.addMeasurement, dialog } }
}

export const addMeasurementRequest = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, addMeasurement: request(state.addMeasurement) }
}

export const addMeasurementSuccess = (state: IPatientState = INITIAL_STATE, action: IActionType<{ measure: any }>) => {
    const { measure } = action.payload
    return { ...state, addMeasurement: success({ ...state.addMeasurement, measure, dialog: false }) }
}

export const addMeasurementFailure = (state: IPatientState = INITIAL_STATE) => {
    return { ...state, addMeasurement: failure(state.addMeasurement) }
}

export const loadMeasurementRequest = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            chart: request({ ...state.measurements.chart })
        }
    }
}

export const loadMeasurementSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ measurements: Measurement[] }>) => {
    const { measurements } = action.payload
    return {
        ...state,
        measurements: {
            ...state.measurements,
            chart: success({ ...state.measurements.chart, measurements })
        }
    }
}

export const loadMeasurementFailure = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            chart: failure({ ...state.measurements.chart })
        }
    }
}

export const changeListMeasurementPaginator = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>
) => {
    const { paginator } = action.payload
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: request({ ...state.measurements.list, paginator })
        }
    }
}

export const loadListMeasurementRequest = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: request({
                ...state.measurements.list,
                paginator: paginator ? paginator : state.measurements.list.paginator
            })
        }
    }
}

export const resetListMeasurement = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: INITIAL_STATE.measurements.list
        }
    }
}

export const loadListMeasurementSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ measurements: Measurement[], headers }>) => {
    const { measurements, headers } = action.payload
    const paginator = {
        ...state.measurements.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: success({ ...state.measurements.list, measurements, paginator })
        }
    }
}

export const loadListMeasurementFailure = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: failure({ ...state.measurements.list })
        }
    }
}

export const loadListMoreMeasurementSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ measurements: Measurement[], headers }>) => {
    const { measurements, headers } = action.payload
    const stateMeasurements = state.measurements.list.measurements
    const paginator = {
        ...state.measurements.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: success({
                ...state.measurements.list,
                measurements: stateMeasurements.concat(measurements),
                paginator
            })
        }
    }
}


export const changeDialogRemoveMeasurement = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ dialog: boolean, ids: string[] }>) => {
    const { dialog, ids } = action.payload
    return {
        ...state,
        measurements: {
            ...state.measurements,
            remove: {
                ...state.measurements.remove,
                dialog,
                ids
            }
        }
    }
}

export const removeMeasurementRequest = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            remove: request({ ...state.measurements.remove, dialog: true })
        }
    }
}

export const removeMeasurementSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ ids: string[], type: TypeMeasurement }>) => {
    const { ids, type } = action.payload
    const measurements = type === TypeMeasurement.HEART_RATE_VARIABILITY ?
        state
            .measurements
            .list
            .measurements
            ?.map((measurement: any) => {
                if (ids.some(removed => measurement?.date === removed)) {
                    return new HeartRateVariability().fromJSON({
                        date: measurement?.date
                    })
                }
                return measurement
            })
        :
        state
            .measurements
            .list
            .measurements
            ?.filter((measurement: any) => !ids.some(removed => measurement?.id === removed))

    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: {
                ...state.measurements.list,
                measurements
            },
            remove: success({ ...INITIAL_STATE.measurements.remove })
        }

    }
}

export const removeMeasurementFailure = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            remove: failure(state.measurements.remove)
        }
    }
}

export const hrvSyncRequest = (state: IPatientState = INITIAL_STATE) => {
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: request(state.measurements.list)
        }
    }
}

export const hrvSyncSuccess = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ hrv: Array<HeartRateVariability | any>, startDate: string, endDate: string }>) => {
    const { hrv, startDate, endDate } = action.payload
    let measurements: any[] = []
    if (!hrv.length) {
        measurements = state
            .measurements
            .list
            .measurements
            ?.map((measurement: any) => {
                if (moment(measurement.date).isBetween(startDate, endDate, undefined, '[]')) {
                    return new HeartRateVariability()
                        .fromJSON({ date: measurement.date })
                        .withFail()
                }
                return measurement
            })
    } else {
        measurements = state
            .measurements
            .list
            .measurements
            ?.map((measurement: any) => {
                if (moment(measurement.date).isBetween(startDate, endDate, undefined, '[]')) {
                    const newHrv: HeartRateVariability | undefined = hrv
                        .find((element: HeartRateVariability) => {
                            return element
                                ?.date
                                ?.substr(0, 10) === measurement
                                ?.date
                                ?.substr(0, 10)
                        })
                    if (newHrv) {
                        return newHrv
                    }
                }
                return measurement
            })
    }
    const startDateFormatted = startDate.substr(0, 10)

    const chartMeasurements = state
        .measurements
        .chart
        .measurements
        .filter((element: any) => element?.date.substr(0, 10) !== startDateFormatted)
        .concat(hrv)

    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: success({
                ...state.measurements.list,
                measurements
            }),
            chart: {
                ...state.measurements.chart,
                measurements: chartMeasurements
            }
        }
    }
}

export const hrvSyncFailure = (
    state: IPatientState = INITIAL_STATE,
    action: IActionType<{ startDate: string, endDate: string }>) => {
    const { startDate, endDate } = action.payload
    const measurements = state
        .measurements
        .list
        .measurements
        ?.map((measurement: any) => {
            if (moment(measurement.date).isBetween(startDate, endDate, undefined, '[]')) {
                return new HeartRateVariability()
                    .fromJSON({ date: measurement.date })
                    .withFail()
            }
            return measurement
        })
    return {
        ...state,
        measurements: {
            ...state.measurements,
            list: failure({
                ...state.measurements.list,
                measurements
            })
        }
    }
}

const reducer: Reducer<IPatientState> = createReducer<IPatientState>(INITIAL_STATE, {
    [PatientTypes.LOAD_DASHBOARD_REQUEST]: loadDashboardRequest,
    [PatientTypes.LOAD_DASHBOARD_SUCCESS]: loadDashboardSuccess,
    [PatientTypes.LOAD_DASHBOARD_FAILURE]: loadDashboardFailure,

    [PatientTypes.LOAD_DASHBOARD_SLEEP_SUCCESS]: loadDashboardSleepSuccess,
    [PatientTypes.LOAD_DASHBOARD_SLEEP_FAILURE]: loadDashboardSleepFailure,

    [PatientTypes.LOAD_DASHBOARD_HRV_SUCCESS]: loadDashboardHRVSuccess,
    [PatientTypes.LOAD_DASHBOARD_HRV_FAILURE]: loadDashboardHRVFailure,

    [PatientTypes.LOAD_DASHBOARD_GAIT_SPEED_SUCCESS]: loadDashboardGaitSpeedSuccess,
    [PatientTypes.LOAD_DASHBOARD_GAIT_SPEED_FAILURE]: loadDashboardGaitSpeedFailure,

    [PatientTypes.RESET_LAST_MEASUREMENT]: resetLastMeasurement,
    [PatientTypes.LOAD_LAST_MEASUREMENT_REQUEST]: loadLastMeasurementRequest,
    [PatientTypes.LOAD_LAST_MEASUREMENT_SUCCESS]: loadLastMeasurementSuccess,
    [PatientTypes.LOAD_LAST_MEASUREMENT_FAILURE]: loadLastMeasurementFailure,

    [PatientTypes.LOAD_LAST_HEART_RATE_REQUEST]: loadLastHeartRateRequest,
    [PatientTypes.LOAD_LAST_HEART_RATE_SUCCESS]: loadLastHeartRateSuccess,
    [PatientTypes.LOAD_LAST_HEART_RATE_FAILURE]: loadLastHeartRateFailure,

    [PatientTypes.LOAD_DASHBOARD_SLEEP_FAILURE]: loadDashboardSleepFailure,

    [PatientTypes.RESET_DASHBOARD]: resetDashboard,
    [PatientTypes.RESET_MEASUREMENTS]: resetMeasurements,

    [PatientTypes.CHANGE_DIALOG_ADD_MEASUREMENT]: changeDialogAddMeasurement,
    [PatientTypes.ADD_MEASUREMENT_REQUEST]: addMeasurementRequest,
    [PatientTypes.ADD_MEASUREMENT_SUCCESS]: addMeasurementSuccess,
    [PatientTypes.ADD_MEASUREMENT_FAILURE]: addMeasurementFailure,

    [PatientTypes.LOAD_MEASUREMENT_REQUEST]: loadMeasurementRequest,
    [PatientTypes.LOAD_MEASUREMENT_SUCCESS]: loadMeasurementSuccess,
    [PatientTypes.LOAD_MEASUREMENT_FAILURE]: loadMeasurementFailure,

    [PatientTypes.CHANGE_LIST_MEASUREMENT_PAGINATOR]: changeListMeasurementPaginator,
    [PatientTypes.LOAD_LIST_MEASUREMENT_REQUEST]: loadListMeasurementRequest,
    [PatientTypes.LOAD_LIST_MEASUREMENT_SUCCESS]: loadListMeasurementSuccess,
    [PatientTypes.LOAD_LIST_MEASUREMENT_FAILURE]: loadListMeasurementFailure,
    [PatientTypes.RESET_LIST_MEASUREMENT]: resetListMeasurement,

    [PatientTypes.LOAD_MORE_LIST_MEASUREMENT_REQUEST]: loadListMeasurementRequest,
    [PatientTypes.LOAD_MORE_LIST_MEASUREMENT_SUCCESS]: loadListMoreMeasurementSuccess,
    [PatientTypes.LOAD_MORE_LIST_MEASUREMENT_FAILURE]: loadListMeasurementFailure,

    [PatientTypes.CHANGE_DIALOG_REMOVE_MEASUREMENT]: changeDialogRemoveMeasurement,
    [PatientTypes.REMOVE_MEASUREMENT_REQUEST]: removeMeasurementRequest,
    [PatientTypes.REMOVE_MEASUREMENT_SUCCESS]: removeMeasurementSuccess,
    [PatientTypes.REMOVE_MEASUREMENT_FAILURE]: removeMeasurementFailure,

    [PatientTypes.HRV_SYNC_REQUEST]: hrvSyncRequest,
    [PatientTypes.HRV_SYNC_SUCCESS]: hrvSyncSuccess,
    [PatientTypes.HRV_SYNC_FAILURE]: hrvSyncFailure
})

export default reducer
