import { all, apply, put, takeLatest } from 'redux-saga/effects'
import {
    loadListMoreTimeSeriesSuccess,
    loadListTimeSeriesFailure,
    loadListTimeSeriesSuccess,
    loadTimeSeriesFailure,
    loadTimeSeriesSuccess,
    viewTimeSeriesFailure,
    viewTimeSeriesSuccess
} from './actions'
import { TimeSeriesTypes } from './types'
import timeSeriesService from '../../../../services/time.series'
import { IActionType, IAxiosResponse, IPaginator } from '../../root.types'
import { TimeSeriesDatePicker, TimeSeriesType } from '../../../application/models/time.series/time.series'
import Measurement from '../../../application/models/measurements/measurement'
import heartRateVariabilityService from '../../../../services/heart.rate.variability'
import { loadListMoreMeasurementFailure, loadListMoreMeasurementSuccess } from '../actions'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'

function* loadTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter, dataPicker } = action.payload
        let response: any
        if (dataPicker === TimeSeriesDatePicker.DAY) {
            response = yield apply(timeSeriesService, timeSeriesService.getResourceWithInterval, [patientId, resource, filter])
        } else if (dataPicker === TimeSeriesDatePicker.WEEK) {
            response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        } else if (dataPicker === TimeSeriesDatePicker.MONTH) {
            response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        } else if (dataPicker === TimeSeriesDatePicker.YEAR) {
            response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        }
        yield put(loadTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadTimeSeriesFailure())
    }
}

function* loadListTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        yield put(loadListTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadListTimeSeriesFailure())
    }
}

function* loadListMoreTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        yield put(loadListMoreTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadListTimeSeriesFailure())
    }
}

function* viewTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const response = yield apply(timeSeriesService, timeSeriesService.getResourceWithInterval, [patientId, resource, filter])
        if (resource === TimeSeriesType.HEART_RATE_VARIABILITY) {
            try {
                const paginator: IPaginator = {
                    first: 0,
                    rows: 10,
                    page: 0,
                    pageCount: 0,
                    totalRecords: 0,
                    search: {
                        key: '',
                        value: ''
                    }
                }
                const hrvFilter: TimeSeriesSimpleFilter = new TimeSeriesSimpleFilter().fromJSON({
                    start_date: filter?.date,
                    end_date: filter?.date
                })
                const hrvResponse: IAxiosResponse<Measurement[]> = yield apply(
                    heartRateVariabilityService,
                    heartRateVariabilityService.getAll,
                    [patientId, paginator, hrvFilter]
                )
                yield put(loadListMoreMeasurementSuccess(hrvResponse))
            } catch (e) {
                yield put(loadListMoreMeasurementFailure())
            }
        }
        yield put(viewTimeSeriesSuccess(response))
    } catch (e) {
        yield put(viewTimeSeriesFailure())
    }
}

export default function* patientTimeSeriesSaga() {
    return yield all([
        takeLatest(TimeSeriesTypes.LOAD_TIMESERIES_REQUEST, loadTimeSeries),
        takeLatest(TimeSeriesTypes.LIST_TIMESERIES_REQUEST, loadListTimeSeries),
        takeLatest(TimeSeriesTypes.LIST_MORE_TIMESERIES_REQUEST, loadListMoreTimeSeries),
        takeLatest(TimeSeriesTypes.VIEW_TIMESERIES_REQUEST, viewTimeSeries)
    ])
}
