import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import TimeSeries from '../../application/models/time.series/time.series'
import HeartRate from '../../application/models/time.series/heart.rate/heart.rate'
import { TimeSeriesSimpleFilter } from '../../application/models/time.series/filters/simple.filter'
import Sleep, { SleepType } from '../../application/models/activity/sleep/sleep'
import BloodGlucose from '../../application/models/measurements/blood.glucose'
import BloodPressure from '../../application/models/measurements/blood.pressure'
import BodyTemperature from '../../application/models/measurements/body.temperature'
import Weight from '../../application/models/measurements/weight'
import BodyFat from '../../application/models/measurements/body.fat'
import Height from '../../application/models/measurements/height'
import WaistCircumference from '../../application/models/measurements/waist.circumference'
import HandGrip from '../../application/models/measurements/hand.grip'
import CalfCircumference from '../../application/models/measurements/calf.circumference'
import { TimeSeriesIntervalFilter } from '../../application/models/time.series/filters/interval.filter'
import IntradayHeartRate from '../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import Measurement from '../../application/models/measurements/measurement'
import { TypeMeasurement } from '../../application/models/measurements/measurements.last'
import HeartRateVariability from '../../application/models/measurements/heart.rate.variability'
import { TimeSeriesFullFilter } from '../../application/models/time.series/filters/full.filter'
import OxygenSaturation from '../../application/models/measurements/oxygen.saturation'
import GaitSpeed from '../../application/models/measurements/gait.speed'
import TSGaitSpeed from '../../application/models/measurements/ts.gait.speed'
import ManualGaitSpeed from '../../application/models/measurements/manual.gait.speed'

/**
 * Patient types
 */
export const PatientTypes = createTypes(`
    RESET_DASHBOARD
    RESET_MEASUREMENTS
    
    LOAD_DASHBOARD_REQUEST
    LOAD_DASHBOARD_SUCCESS
    LOAD_DASHBOARD_FAILURE
    
    LOAD_DASHBOARD_SLEEP_SUCCESS
    LOAD_DASHBOARD_SLEEP_FAILURE
    
    LOAD_DASHBOARD_HRV_SUCCESS
    LOAD_DASHBOARD_HRV_FAILURE
    
    LOAD_DASHBOARD_GAIT_SPEED_SUCCESS
    LOAD_DASHBOARD_GAIT_SPEED_FAILURE
    
    RESET_LAST_MEASUREMENT
    LOAD_LAST_MEASUREMENT_REQUEST
    LOAD_LAST_MEASUREMENT_SUCCESS
    LOAD_LAST_MEASUREMENT_FAILURE

    LOAD_LAST_HEART_RATE_REQUEST
    LOAD_LAST_HEART_RATE_SUCCESS
    LOAD_LAST_HEART_RATE_FAILURE

    CHANGE_DIALOG_ADD_MEASUREMENT
    ADD_MEASUREMENT_REQUEST
    ADD_MEASUREMENT_SUCCESS
    ADD_MEASUREMENT_FAILURE   
    
    LOAD_MEASUREMENT_REQUEST
    LOAD_MEASUREMENT_SUCCESS
    LOAD_MEASUREMENT_FAILURE
    
    CHANGE_LIST_MEASUREMENT_PAGINATOR
    CHANGE_DIALOG_REMOVE_MEASUREMENT
    
    REMOVE_MEASUREMENT_REQUEST
    REMOVE_MEASUREMENT_SUCCESS
    REMOVE_MEASUREMENT_FAILURE
    
    LOAD_LIST_MEASUREMENT_REQUEST
    LOAD_LIST_MEASUREMENT_SUCCESS
    LOAD_LIST_MEASUREMENT_FAILURE
    RESET_LIST_MEASUREMENT
    
    LOAD_MORE_LIST_MEASUREMENT_REQUEST
    LOAD_MORE_LIST_MEASUREMENT_SUCCESS
    LOAD_MORE_LIST_MEASUREMENT_FAILURE
    
    HRV_SYNC_REQUEST
    HRV_SYNC_SUCCESS
    HRV_SYNC_FAILURE
    `,
    {
        prefix: '@patient/'
    }
)

/**
 * Action Types
 */
export interface IActionLoadDashboard {
    readonly patientId: string
    readonly filter: TimeSeriesSimpleFilter
    readonly onlyLastTimeSeries?: boolean
}

export interface IActionLastMeasurements {
    readonly patientId: string
}

export interface IActionLastHeartRate {
    readonly patientId: string
    readonly filter: TimeSeriesIntervalFilter | TimeSeriesFullFilter
}

export interface IActionLoadDevice {
    readonly patientId: string
}

export interface IActionLoadMeasurement {
    readonly patientId: string
    readonly type: TypeMeasurement
    readonly paginator: IPaginator
    readonly filter: TimeSeriesSimpleFilter
}

export interface IActionRemoveMeasurement {
    readonly patientId: string
    readonly ids: string[]
    readonly type: TypeMeasurement
}

export interface IActionAddMeasurement {
    readonly measure: Measurement
    readonly dialog: boolean
    readonly location: AddMeasurementLocation
}

export interface IActionLoadSleep {
    readonly patientId: string
    readonly paginator?: IPaginator
    readonly filter?: TimeSeriesSimpleFilter
}

export interface IActionRemoveSleep {
    readonly patientId: string
    readonly ids: string[]
}

export interface IActionHRVSync {
    readonly patientId: string
    readonly startDate: string
    readonly endDate: string
}

/**
 * IGaitSpeed State
 */
interface IGaitSpeedState extends IComponentState {
    readonly data: Array<GaitSpeed | TSGaitSpeed | ManualGaitSpeed>
}

/**
 * IDashboard State
 */
export interface IDashboardData extends ITimeSeriesData {
    readonly sleep: ISleepStateDashboard
    readonly heartRateVariability: IHeartRateVariabilityState
    readonly gaitSpeed: IGaitSpeedState
}

export interface ITimeSeriesData {
    readonly steps: TimeSeries
    readonly calories: TimeSeries
    readonly active_minutes: TimeSeries
    readonly distance: TimeSeries
    readonly heart_rate: HeartRate
}

interface ISleepStateDashboard extends IComponentState {
    readonly data: Sleep[]
}

interface IHeartRateVariabilityState extends IComponentState {
    readonly heartRateVariability: HeartRateVariability
}

/**
 * Sleep Search interface
 */
export interface ISleepSearch {
    readonly start_time: string
    readonly end_time: string
    readonly type: SleepType
}

/**
 * Sleep Paginator interface
 */
export interface ISleepPaginator {
    readonly first: number
    readonly rows: number
    readonly page: number
    readonly pageCount: number
    readonly totalRecords: number
    readonly search: ISleepSearch
}

export interface IAddMeasure extends IComponentState {
    readonly measure: Measurement
    readonly dialog: boolean
}

export interface ILastMeasurementsData extends IComponentState {
    readonly blood_glucose: BloodGlucose
    readonly blood_pressure: BloodPressure
    readonly body_temperature: BodyTemperature
    readonly weight: Weight
    readonly body_fat: BodyFat
    readonly height: Height
    readonly waist_circumference: WaistCircumference
    readonly hand_grip: HandGrip
    readonly calf_circumference: CalfCircumference
    readonly oxygen_saturation: OxygenSaturation
    readonly gait_speed: GaitSpeed
    readonly ts_gait_speed: TSGaitSpeed
    readonly manual_gait_speed: ManualGaitSpeed
}

export interface IHeartRateData extends IComponentState {
    readonly heart_rate: IntradayHeartRate
}

export interface IMeasurementData extends IComponentState {
    readonly measurements: Measurement[]
}

export interface IRemoveMeasurement extends IComponentState {
    readonly ids: string[]
    readonly dialog: boolean
}

export interface IListMeasurementData extends IMeasurementData {
    readonly measurements: Measurement[]
    readonly paginator: IPaginator
}

export interface IMeasurementState {
    readonly chart: IMeasurementData
    readonly list: IListMeasurementData
    readonly remove: IRemoveMeasurement
}

export enum AddMeasurementLocation {
    LAST_MEASUREMENTS = 'last_measurements',
    HISTORY = 'history'
}

/**
 * State type
 */
export interface IPatientState {
    readonly dashboard: IDashboardData & IComponentState
    readonly lastMeasurement: ILastMeasurementsData
    readonly addMeasurement: IAddMeasure
    readonly heartRate: IHeartRateData
    readonly measurements: IMeasurementState
}
