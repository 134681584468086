import SleepPattern from './pattern'
import Activity from '../activity'
import NightAwakening from './night.awakening'
import SleepStagesSummary from './stages.summary'
import SleepPhasesSummary from './phase.summary'
import moment from 'moment'
import SleepAppleSummary from './apple.summary'

export enum SleepType {
    CLASSIC = 'classic',
    STAGES = 'stages',
    APPLE = 'apple',
}

export enum SleepDatePicker {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

export default class Sleep extends Activity {
    private _pattern: SleepPattern | undefined
    private _type: SleepType | undefined
    private _awakenings: NightAwakening[] | undefined

    get pattern(): SleepPattern | undefined {
        return this._pattern
    }

    set pattern(value: SleepPattern | undefined) {
        this._pattern = value
    }

    get type(): SleepType | undefined {
        return this._type
    }

    set type(value: SleepType | undefined) {
        this._type = value
    }

    get awakenings(): NightAwakening[] | undefined {
        return this._awakenings
    }

    set awakenings(value: NightAwakening[] | undefined) {
        this._awakenings = value
    }

    get efficiency(): number {
        const duration = this.duration
        const summary: SleepPhasesSummary | SleepStagesSummary | SleepAppleSummary | undefined = this.pattern?.summary
        if (summary instanceof SleepStagesSummary) {
            const { deep, light, rem } = summary
            return Math.round(
                (((deep?.duration || 0) + (light?.duration || 0) + (rem?.duration || 0)) / (duration || 1)
                ) * 100)
        }
        if (summary instanceof SleepPhasesSummary) {
            const { asleep } = summary
            return Math.round(((asleep?.duration || 0) / (duration || 1)) * 100)
        }
        if (summary instanceof SleepAppleSummary) {
            const { deep, essential, core, rem } = summary
            return Math.round(
                ((
                        (deep?.duration || 0) +
                        (essential?.duration || 0) +
                        (core?.duration || 0) +
                        (rem?.duration || 0)
                    ) / (duration || 1)
                ) * 100)
        }
        return 0
    }

    public fromJSON(json: any): Sleep {
        super.fromJSON(json)

        if (json.type !== undefined) {
            this.type = json.type
        }

        if (json.pattern !== undefined) {
            this.pattern = new SleepPattern().fromJSON(json.pattern, json.type)
        }

        if (json.awakenings !== undefined) {
            const prevDay: string = moment(this.end_time).subtract(1, 'd').format('YYYY-MM-DD')
            const currentDay: string = moment(this.end_time).format('YYYY-MM-DD')
            const endTime: number = moment(this.end_time).toDate().getTime()
            this.awakenings = json
                ?.awakenings
                ?.map((item: any) => {
                    const itemTime: number = moment(`${currentDay}T${item.end_time}`, 'YYYY-MM-DD[T]HH:mm:ss')
                        .toDate()
                        .getTime()
                    if (itemTime > endTime) {
                        return new NightAwakening()
                            .fromJSON({
                                ...item,
                                start_time: `${prevDay}T${item.start_time}`,
                                end_time: `${prevDay}T${item.end_time}`
                            })
                    } else {
                        return new NightAwakening()
                            .fromJSON({
                                ...item,
                                start_time: `${currentDay}T${item.start_time}`,
                                end_time: `${currentDay}T${item.end_time}`
                            })
                    }
                })
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            pattern: this.pattern ? this.pattern.toJSON() : undefined,
            type: this.type ? this.type : undefined,
            awakenings: this.awakenings ? this.awakenings.map((item: NightAwakening) => item.toJSON()) : undefined
        }
    }
}
