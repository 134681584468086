import SleepDataSet from './data.set'
import SleepPhasesSummary from './phase.summary'
import SleepStagesSummary from './stages.summary'
import { JsonUtils } from '../../../utils/json.util'
import { SleepType } from './sleep'
import SleepAppleSummary from './apple.summary'

export default class SleepPattern {
    private _data_set: SleepDataSet[] | undefined
    private _summary: SleepPhasesSummary | SleepStagesSummary | SleepAppleSummary | undefined

    get data_set(): SleepDataSet[] | undefined {
        return this._data_set
    }

    set data_set(value: SleepDataSet[] | undefined) {
        this._data_set = value
    }

    get summary(): SleepPhasesSummary | SleepStagesSummary | SleepAppleSummary | undefined {
        return this._summary
    }

    set summary(value: SleepPhasesSummary | SleepStagesSummary | SleepAppleSummary | undefined) {
        this._summary = value
    }

    public fromJSON(json: any, type: SleepType): SleepPattern {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.data_set !== undefined) {
            this.data_set = json.data_set.map((item: any) => new SleepDataSet().fromJSON(item))
        }

        if (json.summary !== undefined) {
            if (type === SleepType.CLASSIC) {
                this.summary = new SleepPhasesSummary().fromJSON(json.summary)
            }
            if (type === SleepType.STAGES) {
                this.summary = new SleepStagesSummary().fromJSON(json.summary)
            }
            if (type === SleepType.APPLE) {
                this.summary = new SleepAppleSummary().fromJSON(json.summary)
            }
        }

        return this
    }

    public toJSON(): any {
        return {
            summary: this.summary ? this.summary.toJSON() : undefined,
            data_set: this.data_set ? this.data_set.map((item: SleepDataSet) => item.toJSON()) : undefined
        }
    }
}
