import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import auth from './auth/reducer'
import layout from './layout/reducer'
import snackBar from './snack.bar/reducer'
import user from './user/reducer'
import associates from './user/associates/reducer'
import patient from './patient/reducer'
import timeseries from './patient/timeseries/reducer'
import activities from './patient/activity/reducer'
import sleep from './patient/sleep/reducer'
import devices from './patient/device/reducer'
import report from './patient/report/reducer'
import notes from './patient/note/reducer'

const createRootReducer = (history: History) => combineReducers({
    auth,
    layout,
    router: connectRouter(history),
    snackBar,
    user,
    associates,
    patient,
    timeseries,
    activities,
    notes,
    sleep,
    devices,
    report
})

export default createRootReducer
