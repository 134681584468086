import React, { Component, lazy } from 'react'

import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { bindActionCreators, Dispatch } from 'redux'

import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    isWidthDown,
    WithStyles,
    withStyles,
    withWidth,
    WithWidth
} from '@material-ui/core'
import Diagnosis from '../../../../store/application/models/diagnosis/diagnosis'
import { IApplicationState } from '../../../../store'
import * as NoteReduxActions from '../../../../store/ducks/patient/note/actions'
import { ANIMATION } from '../../../../material.theme'
import { FactoryNote } from '../../../../store/application/utils/factory.note'
import { NoteType } from '../../../../store/application/utils/note.type'
import Note from '../../../../store/application/models/note/note'
import PageTitle from '../../../../components/page.title'
import Patient from '../../../../store/application/models/users/patient'

const NoteForm = lazy(() => import('../../../../components/note/form'))
export const MAP_NOTE_TYPE_LABEL = {
    [NoteType.DIAGNOSTIC]: 'DIAGNOSIS',
    [NoteType.EVOLUTION]: 'EVOLUTION'
}
const Style = () => createStyles({
    ...ANIMATION
})

interface IProps {
    readonly patient: Patient
    readonly type: NoteType | undefined
    readonly patientId: string

    readonly dialog: boolean
    readonly loading: boolean
    readonly note: Diagnosis

    readonly scopeRead: string
    readonly scopeCreate: string
    readonly scopeUpdate: string

    changeDialogCreate(dialog: boolean, diagnosis: Diagnosis): void

    createRequest(diagnosis: Diagnosis): void

    updateRequest(diagnosis: Diagnosis): void

    resetCreate(): void
}

type IJoinProps = IProps & WithTranslation & WithStyles<typeof Style, true> & WithWidth

class CreateUpdateNoteComponent extends Component<IJoinProps> {

    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    public componentDidUpdate(prevProps: Readonly<IJoinProps>, prevState: Readonly<{}>, snapshot?: any) {
        const { dialog } = this.props
        const { dialog: prevDialog } = prevProps
        if (dialog !== prevDialog && !dialog) {
            this.props.resetCreate()
        }
    }

    public render() {
        const {
            classes,
            loading,
            dialog,
            t,
            note,
            changeDialogCreate,
            patientId,
            type,
            width,
            theme,
            patient,
            scopeRead,
            scopeCreate,
            scopeUpdate
        } = this.props

        const radical: string = type ? MAP_NOTE_TYPE_LABEL[type] : ''

        const isXsDown: boolean = isWidthDown('xs', width)

        return <React.Fragment>

            {
                dialog && (
                    <PageTitle
                        title={
                            note?.id ?
                                t(`NAVIGATION_TAB.${radical}.UPDATE`)
                                : t(`NAVIGATION_TAB.${radical}.CREATE`)
                        }/>
                )
            }

            <Dialog
                id="dialog_note"
                maxWidth="md"
                fullWidth={true}
                fullScreen={isXsDown}
                open={dialog}
                scroll="body"
                onClose={() => changeDialogCreate(!dialog, new Diagnosis())}>
                <DialogTitle id="dialog_note_title">
                    <b>
                        {
                            note?.id
                                ? t(`USERS.CREATE.${radical}.UPDATE`)
                                : t(`USERS.CREATE.${radical}.CREATE`)
                        }
                    </b>
                </DialogTitle>

                <DialogContent
                    id="dialog_note_content"
                    style={{ height: '100%' }}
                    className={classes.fadeInContent}>
                    <NoteForm
                        scopeRead={scopeRead}
                        scopeCreate={scopeCreate}
                        scopeUpdate={scopeUpdate}
                        patient={patient}
                        theme={theme}
                        note={note}
                        classes={classes}
                        loading={loading}
                        patientId={patientId}
                        onSubmit={this.onSubmit}
                        cancel={() => {
                            if (type) {
                                changeDialogCreate(false, FactoryNote.build({}, type))
                            }
                        }}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    }

    private onSubmit(values: any): void {
        const { createRequest, updateRequest, note, type } = this.props
        if (type) {
            const newNote: Note = FactoryNote.build({
                ...note,
                ...values,
                date: values?.date?.toISOString()
            }, type)
            note?.id ? updateRequest(newNote) : createRequest(newNote)
        }
    }
}

const CreateUpdateNoteWithTranslation = withTranslation()(CreateUpdateNoteComponent)

const CreateUpdateNoteWithStyle = withStyles<any>(Style, { withTheme: true })(CreateUpdateNoteWithTranslation)

const CreateUpdateNoteWithWidth = withWidth()(CreateUpdateNoteWithStyle)

const mapStateToProps = (state: IApplicationState) => ({
    note: state.notes.create.note,
    dialog: state.notes.create.dialog,
    loading: state.notes.create.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(NoteReduxActions, dispatch)

const CreateUpdateNote = connect(mapStateToProps, mapDispatchToProps)(CreateUpdateNoteWithWidth)

export default CreateUpdateNote
