import Summary from './summary'
import DataSetItem from './data.set.item'
import { JsonUtils } from '../../utils/json.util'

export enum TimeSeriesDatePicker {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

export enum TimeSeriesType {
    STEPS = 'steps',
    CALORIES = 'calories',
    DISTANCE = 'distance',
    ACTIVE_MINUTES = 'active_minutes',
    HEART_RATE = 'heart_rate',
    HEART_RATE_VARIABILITY = 'heart_rate_variability',
    OXYGEN_SATURATION = 'oxygen_saturation'
}

export default class TimeSeries {
    private _summary: Summary | undefined
    private _data_set: DataSetItem[] | undefined


    get summary(): Summary | undefined {
        return this._summary
    }

    set summary(value: Summary | undefined) {
        this._summary = value
    }

    get data_set(): DataSetItem[] | undefined {
        return this._data_set
    }

    set data_set(value: DataSetItem[] | undefined) {
        this._data_set = value
    }

    public fromJSON(json: any): TimeSeries {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.summary !== undefined) {
            this.summary = new Summary().fromJSON(json.summary)
        }

        if (json.data_set !== undefined) {
            this.data_set = json.data_set.map((item: DataSetItem) => new DataSetItem().fromJSON(item))
        }

        return this
    }

    public toJSON(): any {
        return {
            summary: this.summary ? this.summary.toJSON() : undefined,
            data_set: this.data_set ? this.data_set.map((item: DataSetItem) => item.toJSON()) : undefined
        }
    }
}
