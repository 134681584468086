import { action } from 'typesafe-actions'
import { AddMeasurementLocation, IDashboardData, ILastMeasurementsData, PatientTypes } from './types'
import { TimeSeriesSimpleFilter } from '../../application/models/time.series/filters/simple.filter'
import Sleep from '../../application/models/activity/sleep/sleep'
import { TimeSeriesIntervalFilter } from '../../application/models/time.series/filters/interval.filter'
import IntradayHeartRate from '../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import Measurement from '../../application/models/measurements/measurement'
import { TypeMeasurement } from '../../application/models/measurements/measurements.last'
import { IAxiosResponse, IPaginator } from '../root.types'
import HeartRateVariability from '../../application/models/measurements/heart.rate.variability'
import { TimeSeriesFullFilter } from '../../application/models/time.series/filters/full.filter'
import { resetList as resetActivities } from './activity/actions'
import GaitSpeed from '../../application/models/measurements/gait.speed'
import TSGaitSpeed from '../../application/models/measurements/ts.gait.speed'
import ManualGaitSpeed from '../../application/models/measurements/manual.gait.speed'

/* Actions for reset */
export const resetDashboard = () => [
    action(PatientTypes.RESET_DASHBOARD),
    resetActivities()
]

export const resetMeasurements = () => action(PatientTypes.RESET_MEASUREMENTS)

/* Actions for load Time Series */
export const loadDashboardRequest = (patientId: string, filter: TimeSeriesSimpleFilter, onlyLastTimeSeries?: boolean) => action(
    PatientTypes.LOAD_DASHBOARD_REQUEST,
    { patientId, filter, onlyLastTimeSeries }
)

export const loadDashboardSuccess = (data: IDashboardData) => action(
    PatientTypes.LOAD_DASHBOARD_SUCCESS,
    { data }
)

export const loadDashboardFailure = () => action(PatientTypes.LOAD_DASHBOARD_FAILURE)

export const loadDashboardSleepSuccess = (data: Sleep[]) => action(
    PatientTypes.LOAD_DASHBOARD_SLEEP_SUCCESS,
    { data }
)

export const loadDashboardSleepFailure = () => action(PatientTypes.LOAD_DASHBOARD_SLEEP_FAILURE)

export const loadDashboardHRVSuccess = (heartRateVariability: HeartRateVariability) => action(
    PatientTypes.LOAD_DASHBOARD_HRV_SUCCESS,
    { heartRateVariability }
)

export const loadDashboardHRVFailure = () => action(PatientTypes.LOAD_DASHBOARD_HRV_FAILURE)

export const loadDashboardGaitSpeedSuccess = (data: Array<GaitSpeed | TSGaitSpeed | ManualGaitSpeed>) => action(
    PatientTypes.LOAD_DASHBOARD_GAIT_SPEED_SUCCESS,
    { data }
)

export const loadDashboardGaitSpeedFailure = () => action(PatientTypes.LOAD_DASHBOARD_GAIT_SPEED_FAILURE)

/* Actions for load Measurements */
export const resetLastMeasurement = () => action(PatientTypes.RESET_LAST_MEASUREMENT)

export const loadLastMeasurementRequest = (patientId: string) => action(PatientTypes.LOAD_LAST_MEASUREMENT_REQUEST, { patientId })

export const loadLastMeasurementSuccess = (data: ILastMeasurementsData) => action(PatientTypes.LOAD_LAST_MEASUREMENT_SUCCESS, { data })

export const loadLastMeasurementFailure = () => action(PatientTypes.LOAD_LAST_MEASUREMENT_FAILURE)

/* Actions for load last heart rate */
export const loadLastHeartRateRequest = (patientId: string, filter: TimeSeriesIntervalFilter | TimeSeriesFullFilter) =>
    action(PatientTypes.LOAD_LAST_HEART_RATE_REQUEST, { patientId, filter })

export const loadLastHeartRateSuccess = (data: IntradayHeartRate) => action(PatientTypes.LOAD_LAST_HEART_RATE_SUCCESS, { data })

export const loadLastHeartRateFailure = () => action(PatientTypes.LOAD_LAST_HEART_RATE_FAILURE)

export const changeDialogAddMeasurement = (dialog: boolean) => action(PatientTypes.CHANGE_DIALOG_ADD_MEASUREMENT, { dialog })

/* Actions for Add Measurements */
export const addMeasurementRequest = (measure: Measurement, location: AddMeasurementLocation) => action(
    PatientTypes.ADD_MEASUREMENT_REQUEST,
    { measure, location }
)

export const addMeasurementSuccess = (
    measure: Measurement,
    patientId: string,
    location: AddMeasurementLocation) => {
    const pipe = [
        action(PatientTypes.ADD_MEASUREMENT_SUCCESS, { measure }),
        resetMeasurements()
    ]
    if (location === AddMeasurementLocation.LAST_MEASUREMENTS) {
        pipe.push(loadLastMeasurementRequest(patientId))
    }
    if (location === AddMeasurementLocation.HISTORY) {
        const typeSelected: TypeMeasurement = measure?.type as TypeMeasurement
        const gaitSpeedType: string = `${TypeMeasurement.GAIT_SPEED},${TypeMeasurement.TS_GAIT_SPEED},${TypeMeasurement.MANUAL_GAIT_SPEED}`
        const gaitSpeedResources: TypeMeasurement[] = [
            TypeMeasurement.GAIT_SPEED,
            TypeMeasurement.TS_GAIT_SPEED,
            TypeMeasurement.MANUAL_GAIT_SPEED
        ]
        pipe.push(
            loadListMeasurementRequest(
                patientId,
                gaitSpeedResources.includes(typeSelected) ? gaitSpeedType : typeSelected
            )
        )
    }
    return pipe
}

export const addMeasurementFailure = () => action(PatientTypes.ADD_MEASUREMENT_FAILURE)

export const loadMeasurementRequest = (
    patientId: string,
    type: TypeMeasurement,
    filter: TimeSeriesSimpleFilter) => action(
    PatientTypes.LOAD_MEASUREMENT_REQUEST,
    { patientId, type, filter }
)

export const loadMeasurementSuccess = (response: IAxiosResponse<Measurement[]>) => action(
    PatientTypes.LOAD_MEASUREMENT_SUCCESS,
    {
        measurements: response.data,
        headers: response.headers
    }
)

export const loadMeasurementFailure = () => action(PatientTypes.LOAD_MEASUREMENT_FAILURE)

export const changeListMeasurementPaginator = (
    patientId: string,
    type: TypeMeasurement,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => [
    action(PatientTypes.CHANGE_LIST_MEASUREMENT_PAGINATOR, { patientId, type, paginator, filter }),
    loadListMeasurementRequest(patientId, type, paginator, filter)
]

export const loadListMeasurementRequest = (
    patientId: string,
    type: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => action(
    PatientTypes.LOAD_LIST_MEASUREMENT_REQUEST,
    { patientId, type, paginator, filter }
)

export const resetListMeasurement = () => action(PatientTypes.RESET_LIST_MEASUREMENT)

export const loadListMeasurementSuccess = (response: IAxiosResponse<Measurement[]>) => action(
    PatientTypes.LOAD_LIST_MEASUREMENT_SUCCESS,
    {
        measurements: response.data,
        headers: response.headers
    }
)

export const loadListMeasurementFailure = () => action(PatientTypes.LOAD_LIST_MEASUREMENT_FAILURE)

export const loadListMoreMeasurementRequest = (
    patientId: string,
    type: TypeMeasurement,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => action(
    PatientTypes.LOAD_MORE_LIST_MEASUREMENT_REQUEST,
    { patientId, type, paginator, filter }
)

export const loadListMoreMeasurementSuccess = (response: IAxiosResponse<Measurement[]>) => action(
    PatientTypes.LOAD_MORE_LIST_MEASUREMENT_SUCCESS,
    {
        measurements: response.data,
        headers: response.headers
    }
)

export const loadListMoreMeasurementFailure = () => action(PatientTypes.LOAD_MORE_LIST_MEASUREMENT_FAILURE)

export const changeDialogRemoveMeasurement = (dialog: boolean, ids: string[]) => action(
    PatientTypes.CHANGE_DIALOG_REMOVE_MEASUREMENT,
    {
        dialog,
        ids
    }
)

export const removeMeasurementRequest = (patientId: string, ids: string[], type: TypeMeasurement) => action(
    PatientTypes.REMOVE_MEASUREMENT_REQUEST,
    { patientId, ids, type }
)

export const removeMeasurementSuccess = (ids: string[], type: TypeMeasurement) => action(
    PatientTypes.REMOVE_MEASUREMENT_SUCCESS,
    { ids, type }
)

export const removeMeasurementFailure = () => action(PatientTypes.REMOVE_MEASUREMENT_FAILURE)

export const hrvSyncRequest = (patientId: string, startDate: string, endDate: string) => action(
    PatientTypes.HRV_SYNC_REQUEST,
    { patientId, startDate, endDate }
)

export const hrvSyncSuccess = (hrv: Array<HeartRateVariability | undefined>, startDate: string, endDate: string) => action(
    PatientTypes.HRV_SYNC_SUCCESS,
    { hrv, startDate, endDate }
)

export const hrvSyncFailure = (startDate: string, endDate: string) => action(
    PatientTypes.HRV_SYNC_FAILURE,
    { startDate, endDate }
)
