import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../../root.types'
import { ActivityTypes, IActionFindActivity, IActionLoadActivities, IActionLoadTimeSeries, IActionRemoveActivities } from './types'
import {
    findActivityFailure,
    findActivitySuccess,
    loadActivitiesFailure,
    loadActivitiesSuccess,
    loadCaloriesFailure,
    loadCaloriesSuccess,
    loadHeartRateFailure,
    loadHeartRateSuccess,
    loadMoreActivitiesFailure,
    loadMoreActivitiesSuccess,
    removeActivitiesFailure,
    removeActivitiesSuccess
} from './actions'
import physicalActivityService from '../../../../services/physical.activity'
import PhysicalActivity from '../../../application/models/activity/physical/physical'
import { SnackBarMessageType } from '../../../../components/snackbar'
import { open } from '../../snack.bar/actions'
import timeSeriesService from '../../../../services/time.series'
import { TimeSeriesType } from '../../../application/models/time.series/time.series'
import IntradayTimeSeries from '../../../application/models/time.series/intraday/intraday.time.series'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import { push } from 'connected-react-router'

function* findActivity(action: IActionType<IActionFindActivity>) {
    try {
        const { patientId, activityId } = action.payload
        const activity: PhysicalActivity = yield apply(
            physicalActivityService,
            physicalActivityService.getById,
            [patientId, activityId]
        )
        yield put<any>(findActivitySuccess(activity))
    } catch (e) {
        yield put(findActivityFailure())
    }
}

function* loadActivities(action: IActionType<IActionLoadActivities>) {
    try {
        const { patientId, paginator, filter } = action.payload
        const response: IAxiosResponse<PhysicalActivity[]> = yield apply(
            physicalActivityService,
            physicalActivityService.getAll,
            [patientId, paginator, filter]
        )
        yield put(loadActivitiesSuccess(response))
    } catch (e) {
        yield put(loadActivitiesFailure())
    }
}

function* loadMoreActivities(action: IActionType<IActionLoadActivities>) {
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<PhysicalActivity[]> = yield apply(
            physicalActivityService,
            physicalActivityService.getAll,
            [patientId, paginator]
        )
        yield put(loadMoreActivitiesSuccess(response))
    } catch (e) {
        yield put(loadMoreActivitiesFailure())
    }
}

function* removeActivities(action: IActionType<IActionRemoveActivities>) {
    try {
        const { patientId, ids, location } = action.payload
        for (const activityId of ids) {
            yield apply(
                physicalActivityService,
                physicalActivityService.remove,
                [patientId, activityId]
            )
        }
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.ACTIVITIES.REMOVE.REMOVED_SUCCESS')
        )
        if(location === 'DETAILS'){
            yield put(push(`/app/patients/${patientId}/physical_activities`))
        }
        yield put(removeActivitiesSuccess(ids))
    } catch (e) {
        yield put(removeActivitiesFailure())
    }
}

function* loadCalories(action: IActionType<IActionLoadTimeSeries>) {
    try {
        const { patientId, filter } = action.payload
        const calories: IntradayTimeSeries = yield apply(
            timeSeriesService,
            timeSeriesService.getResourceWithTime,
            [patientId, TimeSeriesType.CALORIES, filter]
        )
        yield put(loadCaloriesSuccess(calories))
    } catch (e) {
        yield put(loadCaloriesFailure())
    }
}

function* loadHeartRate(action: IActionType<IActionLoadTimeSeries>) {
    try {
        const { patientId, filter } = action.payload
        const hearRate: IntradayHeartRate = yield apply(
            timeSeriesService,
            timeSeriesService.getResourceWithTime,
            [patientId, TimeSeriesType.HEART_RATE, filter]
        )
        yield put(loadHeartRateSuccess(hearRate))
    } catch (e) {
        yield put(loadHeartRateFailure())
    }
}

export default function* patientSaga() {
    return yield all([
        takeLatest(ActivityTypes.FIND_REQUEST, findActivity),
        takeLatest(ActivityTypes.LOAD_REQUEST, loadActivities),
        takeLatest(ActivityTypes.LOAD_MORE_REQUEST, loadMoreActivities),
        takeLatest(ActivityTypes.REMOVE_REQUEST, removeActivities),
        takeLatest(ActivityTypes.LOAD_CALORIES_REQUEST, loadCalories),
        takeLatest(ActivityTypes.LOAD_HEART_RATE_REQUEST, loadHeartRate)
    ])
}
