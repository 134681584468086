import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import PhysicalActivity from '../store/application/models/activity/physical/physical'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'

class PhysicalActivityService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(
        patientId: string,
        paginator?: IPaginator,
        filter?: TimeSeriesSimpleFilter
    ): Promise<IAxiosResponse<PhysicalActivity[]>> {
        const params: URLSearchParams = this.buildParams(paginator, filter)

        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/physicalactivities`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: response.data.map(activity => new PhysicalActivity().fromJSON(activity)),
                    headers: response.headers
                }
            })
    }

    public getById(patientId: string, activityId: string): Promise<PhysicalActivity> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/physicalactivities/${activityId}`)
            .then((response: IAxiosResponse<any>) => new PhysicalActivity().fromJSON(response.data))
    }

    public remove(patientId: string, activityId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/patients/${patientId}/physicalactivities/${activityId}`)
    }

    private buildParams(
        paginator?: IPaginator,
        filter?: TimeSeriesSimpleFilter
    ): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = paginator?.search?.key && paginator?.search?.value
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `${paginator?.search?.value}`)
            }
        }
        if (filter?.start_date) {
            const endDate = filter?.end_date ? filter?.end_date : filter?.start_date
            params.append('start_time', `gte:${filter?.start_date}T00:00:00`)
            params.append('end_time', `lt:${endDate}T23:59:59`)
        }

        params.append('sort', '-start_time')

        return params
    }

}

export default new PhysicalActivityService()
