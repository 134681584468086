import { JsonUtils } from '../../../utils/json.util'

export enum SleepPhases {
    AWAKE = 'awake',
    ASLEEP = 'asleep',
    RESTLESS = 'restless'
}

export enum SleepStages {
    DEEP = 'deep',
    LIGHT = 'light',
    REM = 'rem',
    AWAKE = 'awake'
}

export enum SleepAppleStages {
    IN_BED = 'inbed',
    AWAKE = 'awake',
    ASLEEP = 'asleep',
    REM = 'rem',
    CORE = 'core',
    ESSENTIAL = 'essential',
    DEEP = 'deep',
}

export const MAP_SLEEP_NAME_TRANSLATE = {
    [SleepPhases.AWAKE]: 'PATIENTS.SLEEP.PHASES.AWAKE',
    [SleepPhases.ASLEEP]: 'PATIENTS.SLEEP.PHASES.ASLEEP',
    [SleepPhases.RESTLESS]: 'PATIENTS.SLEEP.PHASES.RESTLESS',

    [SleepStages.AWAKE]: 'PATIENTS.SLEEP.STAGES.AWAKE',
    [SleepStages.DEEP]: 'PATIENTS.SLEEP.STAGES.DEEP',
    [SleepStages.REM]: 'PATIENTS.SLEEP.STAGES.REM',
    [SleepStages.LIGHT]: 'PATIENTS.SLEEP.STAGES.LIGHT',

    [SleepAppleStages.IN_BED]: 'PATIENTS.SLEEP.APPLE.IN_BED',
    [SleepAppleStages.AWAKE]: 'PATIENTS.SLEEP.APPLE.AWAKE',
    [SleepAppleStages.ASLEEP]: 'PATIENTS.SLEEP.APPLE.ASLEEP',
    [SleepAppleStages.REM]: 'PATIENTS.SLEEP.APPLE.REM',
    [SleepAppleStages.CORE]: 'PATIENTS.SLEEP.APPLE.CORE',
    [SleepAppleStages.ESSENTIAL]: 'PATIENTS.SLEEP.APPLE.ESSENTIAL',
    [SleepAppleStages.DEEP]: 'PATIENTS.SLEEP.APPLE.DEEP'
}

export default class SleepDataSet {
    private _start_time: string | undefined
    private _name: SleepPhases | SleepStages | SleepAppleStages | undefined
    private _duration: number | undefined


    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        this._start_time = value
    }

    get name(): SleepPhases | SleepStages | SleepAppleStages | undefined {
        return this._name
    }

    set name(value: SleepPhases | SleepStages | SleepAppleStages | undefined) {
        this._name = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    public fromJSON(json: any): SleepDataSet {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        return this
    }

    public toJSON(): any {
        return {
            name: this.name ? this.name : undefined,
            start_time: this.start_time ? this.start_time : undefined,
            duration: this.duration ? this.duration : undefined
        }
    }
}
