import { all, fork } from 'redux-saga/effects'

import authSaga from './auth/sagas'
import userSaga from './user/sagas'
import associatesSaga from './user/associates/sagas'
import patientSaga from './user/patient/sagas'
import patientResourcesSaga from './patient/sagas'
import patientTimeSeries from './patient/timeseries/saga'
import activities from './patient/activity/sagas'
import notes from './patient/note/sagas'
import sleep from './patient/sleep/sagas'
import devices from './patient/device/sagas'
import report from './patient/report/sagas'

export default function* rootSaga() {
    return yield all([
        fork(authSaga),
        fork(userSaga),
        fork(associatesSaga),
        fork(patientSaga),
        fork(patientResourcesSaga),
        fork(patientTimeSeries),
        fork(activities),
        fork(notes),
        fork(sleep),
        fork(devices),
        fork(report)
    ])
}
