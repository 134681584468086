import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../../root.types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import PhysicalActivity from '../../../application/models/activity/physical/physical'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import IntradayTimeSeries from '../../../application/models/time.series/intraday/intraday.time.series'
import { TimeSeriesFullFilter } from '../../../application/models/time.series/filters/full.filter'

/**
 * Patient types
 */
export const ActivityTypes = createTypes(`
    RESET_LIST
    RESET_DETAILS
    
    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE
    
    LOAD_CALORIES_REQUEST
    LOAD_CALORIES_SUCCESS
    LOAD_CALORIES_FAILURE
    
    LOAD_HEART_RATE_REQUEST
    LOAD_HEART_RATE_SUCCESS
    LOAD_HEART_RATE_FAILURE
          
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    CHANGE_PAGINATOR
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE
    
    CHANGE_DIALOG_REMOVE
    
    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE    
    `,
    {
        prefix: '@patient.activities/'
    }
)

/**
 * Action Types
 */
export interface IActionFindActivity {
    readonly patientId: string
    readonly activityId: string
}

export interface IActionLoadTimeSeries {
    readonly patientId: string
    readonly filter: TimeSeriesFullFilter
}

export interface IActionLoadActivities {
    readonly patientId: string
    readonly paginator?: IPaginator
    readonly filter?: TimeSeriesSimpleFilter
}

export interface IActionRemoveActivities {
    readonly patientId: string
    readonly ids: string[]
    readonly location: 'LIST' | 'DETAILS'
}

/**
 * Activities State
 */
interface IListState extends IComponentState {
    readonly activities: PhysicalActivity[]
    readonly paginator: IPaginator

}

export interface IRemoveState extends IComponentState {
    readonly ids: string[]
    readonly dialog: boolean
}

export interface ICaloriesState extends IComponentState {
    readonly calories: IntradayTimeSeries
}

export interface IHeartRateState extends IComponentState {
    readonly heartRate: IntradayHeartRate
}

export interface IDetailsState extends IComponentState {
    readonly activity: PhysicalActivity
    readonly calories: ICaloriesState
    readonly heartRate: IHeartRateState
}


/**
 * State type
 */
export interface IActivityState {
    readonly details: IDetailsState
    readonly list: IListState
    readonly remove: IRemoveState
}
